<template>
  <div>
    <div class="e-breadcrumb">卖品提成配置管理</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-handle-box" style="margin-bottom: 50px">
            <div class="handle-box">
              <span class="v-form-label">卖品编号:</span>
              <el-input placeholder="卖品编号" size="small" style="width:265px" v-model="skuNo" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">卖品名称:</span>
              <el-input placeholder="卖品名称" size="small" style="width:265px" v-model="skuName" clearable
                        class="handle-input mr10"></el-input>
              <div style="float: left">
                <el-row type="flex" justify="end">
                  <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                  </el-button>
                </el-row>
              </div>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellspacing="0" cellpadding="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th class="th-l" width="188">编号</th>
                <th class="th-l" width="188">名称</th>
                <th class="th-l" width="188">销售提成</th>
                <th class="th-l" width="188">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l">
                  {{row.skuNo}}
                </td>
                <td class="td-l">
                  {{row.skuName}}
                </td>
                <td class="td-l">
                  <div v-if="row.commissionType">
                        <span class="it-sp"v-if="row.commissionType==0">{{row.commissionValue}} %</span>
                        <span class="it-sp" v-if="row.commissionType==1">{{row.commissionValue}} 元/个</span>
                  </div>
                  <div v-else>
                    <span class="it-sp">未设置</span>
                  </div>
                </td>
                <td class="td-l">
                    <span class="xinzeng" style="margin-right: 6px" v-if="row.commissionType"  @click="removeEvent(row)">删除</span>
                    <span class="xinzeng"  v-if="row.commissionType" @click="editEvent(row)" >修改</span>
                    <span class="xinzeng" v-if="!row.commissionType"  @click="addSkuCommission(row)">新增</span>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

<!--     新增提成方案弹框-->
    <el-dialog title="新增提成方案" :visible.sync="addVisible" width="50%" >
      <hr style="margin-top: -26px">
      <div class="dialog-left" style="margin-top: 8px">
        <span>卖品名称</span>
        <span style="margin-left: 18px;background-color: #C4C4C4;padding: 2px 8px 2px 8px">{{item.skuName}}</span>
      </div>
      <div class="dialog-left">
        <span>提成设置</span>
        <div class="yeji">
          <div>
            <el-radio-group v-model="commissionType" style="margin-left: 8px">
              <el-radio :label="1">固定提成</el-radio>
              <el-radio :label="0">业绩比例</el-radio>
            </el-radio-group>
            <el-input v-model="commissionValue" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')" placeholder="请输入内容" size="small" style="width: 128px;margin-left: 16px"></el-input>
            <span v-if="commissionType==1" style="font-size: 16px;margin-left: 6px">元 / 个</span>
            <span v-if="commissionType==0" style="font-size: 16px;margin-left: 6px">%</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="saveSskuCommission">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 编辑提成方案弹框 -->
    <el-dialog title="新增提成方案" :visible.sync="editVisible" width="50%" >
      <hr style="margin-top: -26px">
      <div class="dialog-left" style="margin-top: 8px">
        <span>卖品名称</span>
        <span style="margin-left: 18px;background-color: #C4C4C4;padding: 2px 8px 2px 8px">{{upName}}</span>
      </div>
      <div class="dialog-left">
        <span>提成设置</span>
        <div class="yeji">
          <div>
            <el-radio-group v-model="commissionType" style="margin-left: 8px">
              <el-radio :label="'1'">固定提成</el-radio>
              <el-radio :label="'0'">业绩比例</el-radio>
            </el-radio-group>
            <el-input v-model="commissionValue" onkeyup="value=value.replace(/[^(\d||/.)]/g,'')" placeholder="请输入内容" size="small" style="width: 128px;margin-left: 16px"></el-input>
            <span v-if="commissionType=='1'" style="font-size: 16px;margin-left: 6px">元 / 个</span>
            <span v-if="commissionType=='0'" style="font-size: 16px;margin-left: 6px">%</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="updateSkuCommission">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delSkuCommission">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
  import {Base_url} from '../../config/index'

  export default {
    data() {
      return {
        base_url: '',

        skuNo:'',
        skuName:'',

        item:'',


        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        addVisible: false,
        commissionType:1,
        commissionValue:0,

        editVisible: false,
        scId:'',
        upName:'',
        editItem: {},

        delVisible: false,
        delItem: {},

      }
    },
    async created() {
      this.searchEvent();
      this.base_url = Base_url
    },
    methods: {
      addSkuCommission(item){
        this.commissionType=1,
        this.commissionValue=0,
        this.item=item
        this.addVisible=true
        this.commissionValue=0
      },


      //保存项目提配置
      async saveSskuCommission(){
        let data = {
          commissionType:this.commissionType,
          commissionValue:this.commissionValue,
          skuId:this.item.sId,
        }
        console.log(data)
        let res = await this.$post("/admin/saveSkuCommission", data)
        if (res.code==200){
          this.$message.success(res.message)
          this.requestSearch()
          this.addVisible=false
        }else {
          this.$message.error(res.message)
        }
      },

      //编辑项目提配置
      async updateSkuCommission(){
        let data = {
          id:this.scId,
          commissionType:this.commissionType,
          commissionValue:this.commissionValue,
        }
        let res = await this.$post("/admin/saveSkuCommission", data)
        if (res.code==200){
          this.$message.success(res.message)
          this.requestSearch()
          this.editVisible=false
        }else {
          this.$message.error(res.message)
        }
      },

      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },

      //分页获取卖品提成配置
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          skuNo: this.skuNo,
          skuName: this.skuName,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let dataSet = await this.$get("/admin/getSkuCommissionList", data)
        let res = dataSet.dataSet
        let list = res.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },

      addEvent() {
        this.addVisible = true;
      },

      editEvent(item) {
        this.scId = item.id
        this.upName = item.skuName
        this.commissionType = item.commissionType
        this.commissionValue = item.commissionValue
        this.editVisible = true;
      },

      removeEvent(item) {
        this.delItem=item
        this.delVisible = true;
      },

      async delSkuCommission(){
        let data = {
          id:this.delItem.id,
        }
        let res = await this.$post("/admin/delSkuCommission", data)
        if (res.code==200){
          this.$message.success(res.message)
          this.requestSearch()
          this.delVisible=false
        }else {
          this.$message.error(res.message)
        }
      },
    },
  }

</script>

<style scoped>

  .th-l {
    text-align: center;
    padding-right: 10px;

  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  .xinzeng {
    margin: 0 auto;
    width: 38px;
    color: #1890ff;
    cursor:pointer;
  }
  .dialog-left{
    font-size: 16px;
    text-align: left;
  }
  .dialog-left span{
    line-height: 38px;
  }
  .yeji{
    margin-left: 38px;
    background-color: #FFDDDD;
    padding: 8px 8px 8px 8px;
    width: 88%;
  }
  .yeji-span{
    width:66px;
    display:inline-block;
    text-align: right;
    margin-right: 16px;
  }
  .it-sp{
    margin-right: 6px;
    font-weight: bold;
    font-size: 10px;
  }
</style>
